.react-confirm-alert > h1, .react-confirm-alert  {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    word-wrap: break-word;
}

.react-confirm-alert-overlay {
    z-index: 1051;
}

#react-confirm-alert .react-confirm-alert > h1 {
    color: #000;
    margin-bottom: 10px;
}

#react-confirm-alert .react-confirm-alert h1 {
  font-size: 6vw;
}

#react-confirm-alert .react-confirm-alert {
    width:90%;
}

#react-confirm-alert .react-confirm-alert > h3 {
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-body {
  width: auto;
  text-align: center;
  max-width: 100%;
}

#react-confirm-alert .react-confirm-alert-button-group {
justify-content: center;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
    background: #000;
    letter-spacing: 1.2px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.3);
}
